import { EnvironmentConfig } from '@abb-procure/constants';
import { AuthenticationType } from 'azure-maps-control';

const apiEndpoint = API_ENDPOINT || 'https://api.dev.procure.abb.com/';
const instrumentationKey =
  APPINSIGHTS_INSTRUMENTATIONKEY || '211d74ae-28bb-4587-b49f-90a539eba6e9';
const msalApiEndpointId =
  MSAL_API_ENDPOINT_ID || 'a854c4bd-71ca-42db-8ef9-8c5a331015eb';
const msalClientId = MSAL_CLIENT_ID || 'a854c4bd-71ca-42db-8ef9-8c5a331015eb';
const azureMapsSubscriptionKey =
  MAPS_SUBSCRIPTION_ID || '4k7RGFD-ixDjf0coWPLhVfSJEkNshzlT3qEp19wQvQs';

/**
 * This is the environment configuration for the 'develop' context of the project.
 *
 * The following contexts or environments are available:
 *  - development (environment.ts): For local development.
 *  - develop (environment.develop.ts): First deploy after merges. Development built for production.
 *  - staging (environment.staging.ts): Staging environment before production (acceptance tests).
 *  - production (environment.production.ts): Final stage for live application.
 */
export const environment: EnvironmentConfig = {
  production: true,
  title: 'Development',
  isEnvironmentBannerEnabled: true,
  apiEndpoint,
  msalApiEndpointResource: 'https://graph.windows.net',
  msalConfig: {
    apiEndpointId: msalApiEndpointId,
    clientId: msalClientId,
    instance:
      'https://login.microsoftonline.com/e77d828a-dd9f-4787-bd3c-3b21d7b14ce6/',
    cacheLocation: 'localStorage',
  },
  msalLoggingEnabled: false,
  signalrLoggingEnabled: true,
  instrumentationKey,
  mapOptions: {
    authOptions: {
      authType: AuthenticationType.subscriptionKey,
      subscriptionKey: azureMapsSubscriptionKey,
    },
    baseUrl: 'https://atlas.microsoft.com/',
    endpoints: {
      addressSearch: `search/address/json?&subscription-key=${azureMapsSubscriptionKey}&api-version=1.0&language=de-CH&query=`,
    },
  },
};
